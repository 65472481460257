<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span span v-if="formModel.id">
          <!-- {{ mode === "edit" ? "修改" : "" }}{{ formModel.itemName }} -->
          修改发票
        </span>
        <span span v-if="!formModel.id">创建发票</span>
      </div>
    </div>

    <a-form-model
      :rules="validModel"
      ref="ruleForm"
      :layout="form.layout"
      :model="formModel"
      v-bind="formItemLayout"
    >
      <a-form-model-item label="开票日期" prop="itemCode">
        <a-date-picker
          :disabled="!!formModel.id === true"
          placeholder="请选择开票日期"
          v-model="formModel.receivedData"
        ></a-date-picker>
      </a-form-model-item>

      <a-form-model-item label="客户名称" prop="clientId">
        <a-select
          :disabled="!!formModel.id === true"
          v-model="formModel.clientId"
          :options="customerOptions"
          :filter-option="filterOption"
          @change="getInvoiceOption"
          show-search
          allowClear
        >
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="发票抬头" prop="receiptId">
        <a-select
          :disabled="!!formModel.id === true"
          v-model="formModel.receiptId"
          :options="invoiceOption"
          :filter-option="filterOption"
          @change="getInvoiceInfo"
          show-search
          allowClear
        >
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="发票金额" prop="amount">
        <a-input :disabled="!!formModel.id === true" v-model="formModel.amount" placeholder="请输入金额" ></a-input>
      </a-form-model-item>

      <a-form-model-item label="发票类型" prop="receiptType">
        <a-select :disabled="!!formModel.id === true" v-model="formModel.receiptType" @change="updateTypeName">
          <a-select-option key="1" :value="1">增值税普通发票</a-select-option>
          <a-select-option key="2" :value="2">增值税专用发票</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="邮寄地址" prop="amount">
        <a-input
          v-model="formModel.address"
          placeholder="请输入邮寄地址"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="快递名称" prop="expressDeliveryName">
        <a-input
          v-model="formModel.expressDeliveryName"
          placeholder="请输入快递名称"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="快递单号" prop="expressDeliveryNo">
        <a-input
          v-model="formModel.expressDeliveryNo"
          placeholder="请输入快递单号"
        ></a-input>
      </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
      <a-button type="danger" @click="cancle">取消</a-button>
      <a-button type="primary" @click="onSubmit">保存</a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { message } from "ant-design-vue";
import receiptApi from "../../../shared/service-proxies/receipt/services/receipt-proxies";
import customerApi from "../../../shared/service-proxies/customer/services/customer-proxies";

export default {
  name: "receipt-form",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      customerOptions: [],
      fieldMap: {
        value: "id",
      },
      form: {
        layout: "horizontal",
      },
      mode: "create",
      curDataItemTree: [],
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
      invoiceOption: [],
      //isTop: this.cacl,
      formModel: {
        id: "",
        expressDeliveryNo: "",
        expressDeliveryName: "",
        address: "",
        receiptType: null,
        receiptTypeName: "",
        amount: "",
        receiptTitle: "",
        receiptId: "",
        clientName: "",
        clientId: "",
        receivedData: "",
      },
      validModel: {
        //Id: '',
        // itemName: [
        //     {required: true, message: '请输入!'}
        // ],
        // itemValue: [
        //     {required: true, message: '请输入!'}
        // ],
        // itemCode: [
        //     {required: true, message: '请输入!'}
        // ],
        // sortCode: [
        //     {required: true, message: '请输入!'}
        // ],
        //
        // description: [
        //     {required: true, message: '请输入!'}
        // ],
        // parentId: [
        //     {required: true, message: '请输入!'}
        // ],
        // //  isTop: 1
      },
    };
  },

  components: {},
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.initData();
    console.log(this.formModel)
  },
  mounted() {
    setTimeout(() => {
      if (this.formModel.clientId != "") {
        this.getInvoiceOption(this.formModel.clientId);
      }
    }, 1000);
  },
  computed: {
    formItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : {};
    },
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : {};
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    updateTypeName(val, option) {
      console.log(val, option);
      switch (val) {
        case 0:
          this.formModel.receiptTypeName = "增值税专用发票";
          break;
        case 1:
          this.formModel.receiptTypeName = "增值税专用发票";
          break;
      }
    },
    async initData() {
      this.customerOptions = await customerApi.getOptions();
    },
    cancle() {
      this.close();
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        if (!valid) {
          message.error("表单验证失败，请检查后重新提交！");
          return false;
        }
        receiptApi
          .save(this.formModel)
          .then((data) => {
            this.$notification["success"]({
              message: "操作成功",
            });
            this.success(true);
          })
          .catch((error) => {
            console.log("-----oh 我的玉帝 报错了---------");
            console.log(error);
          });
      });
    },
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
    async getInvoiceInfo(val,option) {
      if (val) {
        let invoice = await customerApi.getInvoice(val);
        this.formModel.receiptType = invoice.type;
      }
    },
    async getInvoiceOption(val) {
      if (val) {
        console.log(val);
        // console.log(this.customerOptions);
        let selectdClient = this.customerOptions.find((item) => {
          return item.value == val;
        });
        this.formModel.clientName = selectdClient.label;
        let options = await customerApi.getInvoiceInfos({ customerId: val });
        this.invoiceOption = options.map((item) => {
          return {
            value: item.value,
            label: item.text,
          };
        });
      } else {
        this.invoiceOption = [];
        this.formModel.receiptTitle = "";
      }
    },
    tabChange(activeKey) {
      this.activeKey = activeKey;
      switch (activeKey) {
        case "1":
          // do sth...
          break;
        case "2":
          this.$refs.seal.getData(this.sealUrl.getPaged);
          break;
        case "3":
          this.$refs.account.getData(this.accountUrl.getPaged);
          break;
        case "4":
          this.$refs.realname.getData(this.realNameUrl.getPaged);
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;

  .anticon {
    margin-right: 10px;
  }
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
