<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="数据项"></page-header> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :scroll="{ x: 1200 }"
      :columns="columns"
      :actionsType="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="3">
          <a-select
            v-model="searchDto.customerId"
            style="width: 100%"
            show-search
            :options="customerOptions"
            :filter-option="filterOption"
            placeholder="请选择客户"
            allowClear
          >
          </a-select>
        </a-col>

        <a-col class="gutter-row" :span="3">
          <a-select
            v-model="searchDto.orgId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :options="orgs"
            placeholder="签约公司"
            allowClear
          >
          </a-select>
        </a-col>

        <a-col class="gutter-row" :span="2">
          <a-select
            v-model="searchDto.Status"
            placeholder="开票状态"
            style="width: 100%"
            allowClear
          >
            <a-select-option
              v-for="item in [
                { label: '未处理', value: 0 },
                { label: '开票', value: 2 },
                { label: '驳回', value: 3 },
                { label: '退票', value: 4 },
                { label: '作废', value: -1 },
              ]"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col class="gutter-row" :span="2">
          <a-month-picker
            v-model="searchDto.month"
            placeholder="月份"
            valueFormat="YYYY-MM"
            style="width: 100%"
            allowClear
          />
        </a-col> -->
        <a-col class="gutter-row" :span="6">
          <a-range-picker
            v-model="searchDto.receivedData"
            :placeholder="['开始时间', '结束时间']"
            valueFormat="YYYY-MM-DD"
            style="width: 100%"
          />
        </a-col>
        <a-col :span="4">
          <a-input
            allow-clear
            :placeholder="l('发票抬头名称')"
            v-model.trim="searchDto.invoiceTitle"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.InvoicePermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>

        <a-col class="gutter-row" :span="2" style="text-align: right">
          <a-button
            type="primary"
            @click="openForm()"
            v-if="isGranted('Pages.InvoicePermissions.Create')"
          >
            新增
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import receiptApi from "../../../shared/service-proxies/receipt/services/receipt-proxies";
import receiptForm from "./form.vue";
import customerApi from "../../../shared/service-proxies/customer/services/customer-proxies";
import orgApi from "../../../shared/service-proxies/org/services/org-proxies";

export default {
  name: "receipt-list",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      tableKey: "ReceiptPagedDto",
      searchDto: {
        month: "",
        Status: undefined,
        customerId: undefined,
        orgId: undefined,
        invoiceTitle: "",
        receivedData: "",
        startTime: "",
        endTime: "",
      },
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      pagerange: [1, 1],
      companies: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      customerOptions: [],
      orgs: [],
      actionsType: {},
      columns: [],
    };
  },
  methods: {
                filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onOk(value) {
      console.log("onOk: ", value);
      this.searchDto.startTime = value[0];
      this.searchDto.endTime = value[1];
    },
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: this.l("开票日期"),
          dataIndex: "receivedData",
          sorter: true,
          // fixed: "left",
          width: 200,
          align: "center",
          customRender: function (text, record) {
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: text.split("T")[0],
              },
              attrs: { title: text.split("T")[0] },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "receivedData" },
        },
        {
          title: this.l("发票抬头"),
          dataIndex: "receiptTitle",
          sorter: false,
          width: 220,
          align: "center",
          scopedSlots: { customRender: "receiptTitle" },
        },
        {
          title: this.l("客户名称"),
          dataIndex: "clientName",
          width: 220,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "clientName" },
        },
        {
          title: this.l("发票金额"),
          dataIndex: "amount",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: this.l("邮寄地址"),
          dataIndex: "address",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "address" },
        },
        {
          title: this.l("发票类型"),
          dataIndex: "receiptTypeName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "receiptTypeName" },
        },
        {
          title: this.l("快递名称"),
          dataIndex: "expressDeliveryName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "expressDeliveryName" },
        },
        {
          title: this.l("快递单号"),
          dataIndex: "expressDeliveryNo",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "expressDeliveryNo" },
        },
        {
          title: this.l("发票状态"),
          dataIndex: "status",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "status" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          width: 100,
          scopedSlots: { customRender: "actions" }, //
        },
      ];
    },
    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _that = this;
      let obj = {
        type: "receipt-list-action",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.InvoicePermissions.Update"),
              name: this.l("修改"),
              icon: "profile",
              fn: (data) => {
                _that.openForm(data);
              },
            },
          ],
          edit: {
            isShow: this.isGranted("Pages.InvoicePermissions.Update"),
            granted: true,
            name: this.l("Edit"),
            fn: () => {
              console.log("edit");
            },
          },
        },
      };
      this.actionsType = obj;
    },
    async initData() {
      this.customerOptions = await customerApi.getOptions();
      this.orgs = (await orgApi.getOptions()).map((item) => {
        return {
          value: item.value,
          label: item.text,
        };
      });
    },
    /**
     * 搜索框
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    openForm(data) {
      let formData = {};
      if (!!data) {
        formData.formModel = data;
      }
      console.log(formData);
      ModalHelper.create(receiptForm, formData, {
        confirm: (data) => {
          // console.log("confirmed !!!!!");
          // console.log(data);
          // this.formModel.interfaceConfig.interfaces.push(data)
          // this.activeInterfaceName = data.name
        },
        width: "800px",
        isChange: true,
      }).subscribe((res, message) => {
        this.getData();
      });
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据 */
    getData() {
            this.searchDto.startTime =
        this.searchDto.receivedData[0] == null ? "" : this.searchDto.receivedData[0];
      this.searchDto.endTime =
        this.searchDto.receivedData[1] == null ? "" : this.searchDto.receivedData[1];
      this.spinning = true;
      receiptApi
        .getPagedList({
          ...this.searchDto,
          Status:
            this.searchDto.Status !== undefined ? this.searchDto.Status : "",
          customerId: this.searchDto.customerId
            ? this.searchDto.customerId
            : "",
          orgId: this.searchDto.orgId ? this.searchDto.orgId : "",
          sorting: this.request.sorting,
          maxResultCount: this.request.maxResultCount,
          skipCount: this.request.skipCount,
        })
        .then((res) => {
          this.spinning = false;
          this.tableData = res.items.map((v) => {
            return {
              ...v,
              status: this.returnStatusStr(v.status),
              receivedData: moment(v.receivedData).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
          });
          this.totalItems = res.totalCount;
          // console.log(res);
          // console.log(this.request.maxResultCount);
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          // console.log(this.totalPages);
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          console.log(this.pagerange);
        })
        .catch((data, error) => {
          this.spinning = false;
        });
    },
    returnStatusStr(type) {
      switch (type) {
        case 0:
          return "未处理";
        // case -1:
        //   return '作废'
        case 2:
          return "开票";
        case 3:
          return "驳回";
        case 4:
          return "退票";
        default:
          return "作废";
      }
    },
    clearFilterAndRefresh() {
      this.searchDto = {
        month: "",
        Status: undefined,
        customerId: undefined,
        orgId: undefined,
        invoiceTitle: "",
        receivedData: "",
        startTime: "",
        endTime: "",
      };
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.getData();
    },
    clearAndGetData() {
      this.searchDto.invoiceTitle = "";
      this.searchDto.receivedData = "";
      this.searchDto.Status = undefined;
      this.searchDto.customerId = undefined;
      this.searchDto.orgId = undefined;
      this.getData();
    },
  },
  created() {
    this.initActionsType();
    this.initData();
    this.initColumns();
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
