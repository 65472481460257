
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let orgApi = {
    namespaces:'Organization',
    instance: apiHttpClient,
    locked: false,
    localStore: {},
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    async getOptions(){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetSelectList";

        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_),
            headers: {
                Accept: "application/json",
            },
        };

        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },

//{customerId：''}
    async getInvoiceInfos(param){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetInvoiceTitleSelectList";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_,param),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    async getInvoice(id){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetCustomerBaseInvoiceById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_,{id:id}),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
 }

export default orgApi